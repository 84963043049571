import Home from "@/components/home/Home";
import NotFound from "@/components/common/NotFound";
import { isProspect } from "@/utils/type";

export default function HomePage({ type }) {
    return isProspect(type) ? <Home /> : <NotFound />;
}

export async function getStaticProps() {
    return {
        props: {
            type: process.env.NEXT_PUBLIC_TYPE,
            meta: {
                title: "Purple Door",
                description:
                    "A Better Way to Connect Seniors with Communities. Purple Door Finders offers a new platform for seniors and their families to make better and more informed decisions about senior living and care options.",
                //jsonLd,
                ogTitle: "Purple Door",
                ogImage:
                    "https://cdn.purpledoorfinders.com/purpledoorfinders/start-living-better.lg.jpg",
            },
        },
    };
}
